<template>
  <div class="home_flex">
    <div class="home_grid">
      <section>
        <div style="cursor: pointer">
          <div style="
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;">
            <img
              :src="$store.state.logoImageUrl"
              style="height: 8.0em; border-radius:10px"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
        <div class="side-link">
          <ul>
            <li
              style="padding-right:5px"
              @click="$router.push({path: '/safari-packages'})"
            >
              <i
                style="padding-right:5px"
                class="el-icon-date"
              ></i> Safari Packages
            </li>


            <li
              style="padding-right:5px"
              @click="$router.push({path: '/blogs'})"
            >
              <i
                style="padding-right:5px"
                class="el-icon-date"
              ></i> Blogs
            </li>
            <li
              v-if="$store.state.currentRole == 'ADMIN'"
              @click="$router.push({path: '/countries'})"
            >
              <i
                style="padding-right:5px"
                class="el-icon-s-flag"
              ></i> Countries
            </li>

            <li @click="$router.push({path: '/experiences'})">
              <i
                style="padding-right:5px"
                class="el-icon-sunrise-1"
              ></i> Experiences
            </li>
<!-- 
            <li @click="$router.push({path: '/hotels'})">
              <i
                style="padding-right:5px"
                class="el-icon-sunrise-1"
              ></i> Hotels
            </li> -->
            <li 
            v-if="$store.state.currentRole == 'ADMIN'"
            @click="$router.push({path: '/safari-types'})">
              <i class="el-icon-truck"></i> Safari Types
            </li>

            <li
              @click="$router.push({path: '/users'})"
              v-if="$store.state.currentRole == 'ADMIN'"
            >
              <i
                style="padding-right:5px"
                class="fas fa-users"
              ></i> Users
            </li>
            <li
              v-if="$store.state.currentRole == 'ADMIN'"
              @click="$router.push({path: '/quotations'})"
            >
              <i
                style="padding-right:5px"
                class="el-icon-document"
              ></i> Safari Quotes
            </li>


            <li
              v-if="$store.state.currentRole == 'ADMIN'"
              @click="$router.push({path: '/news-letter-subscribers'})"
            >
              <i
                style="padding-right:5px"
                class="el-icon-document"
              ></i> NewsLetter Subscribers
            </li>

            <li
              v-if="$store.state.currentRole == 'ADMIN'"
              @click="$router.push({path: '/receipts'})"
            >
              <i
                style="padding-right:5px"
                class="el-icon-document"
              ></i> Receipts
            </li>
            <li @click="logout">
              <i
                style="padding-right:5px"
                class="fas fa-arrow-left"
              ></i> Logout
            </li>
          </ul>
        </div>
      </section>
      <section>
        <div class="theTopNavbar">
          <NavbarComponent />
        </div>
        <div class="container">
          <router-view />
        </div>
        <FooterComponent />
      </section>
    </div>
  </div>
</template>

<script>
import NavbarComponent from "../components/navbar-component.vue";
import FooterComponent from "../components/footer.vue";
import jwt_decode from "jwt-decode";
export default {
  components: {
    NavbarComponent,
    FooterComponent,
  },

  data() {
    return {};
  },

  // then, in the parent component,
  // watch the `$route` to determine the transition to use
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
    },
  },

  mounted() {
    this.setCurrentUser();
  },

  methods: {
    setCurrentUser() {
      let userToken = JSON.parse(localStorage.getItem("user"));
      let decoded = jwt_decode(userToken);
      this.$store.commit("setCurrentUsername", decoded.data.username);
      this.$store.commit("setCurrentFullName", decoded.data.fullname);
      this.$store.commit("setCurrentRole", decoded.data.role);
      this.$store.commit("setCurrentPhoto", decoded.data.image);
    },
    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
      location.reload(true);
    },
  },
};
</script>


<style scoped>
.home_grid {
  width: 100%;
}

.home_grid > section:first-child {
  width: 20%;
  height: 100vh;
  background-color: #e9efec;
  color: black;
  padding: 30px 20px 60px 20px;

  position: fixed;
  top: 0px;
  bottom: 0px;
  overflow: auto;
}
.home_grid > section:nth-child(2) {
  margin-left: 20%;
  padding-top: 60px;
  width: 80%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}

.side-link ul {
  list-style: none;
  padding: 0px;
}
.side-link ul li {
  border-bottom: 1px solid grey;
  padding-top: 30px;
  padding-bottom: 10px;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 600;
}

.side-link ul li:hover {
  padding-left: 30px;
  transition: 0.5s;
  color: grey;
}

.theTopNavbar {
  position: fixed;
  top: 0px;
  left: 20%;
  right: 0px;
  z-index: 100;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .home_grid > section:first-child {
    display: none;
    width: 0px;
    height: 0px;
    padding: 0px;
    position: relative;
  }
  .home_grid > section:nth-child(2) {
    margin-left: 0px;
    padding-top: 50px;
    width: 100%;
  }

  .theTopNavbar {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .home_grid > section:first-child {
    display: none;
    width: 0px;
    height: 0px;
    padding: 0px;
    position: static;
  }
  .home_grid > section:nth-child(2) {
    margin-left: 0px;
    padding-top: 50px;
    width: 100%;
  }

  .theTopNavbar {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
  }
}
</style>