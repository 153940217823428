import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: "",
    currentUserName: "User",
    currentFullName: "Full Name",
    currentUserPhoto: "",
    currentRole: "Role",
    currentCountry: "",
    logoImageUrl: "https://api.ihambaadventures.com/destinations/ihamba.png",
  },
  mutations: {
    //The synchronous way to update our Vuex in our app store.
    setCurrentUserId(state, payload) {
      state.userId = payload;
    },
    setCurrentUsername(state, payload) {
      state.currentUserName = payload;
    },
    setCurrentFullName(state, payload) {
      state.currentFullName = payload;
    },
    setCurrentRole(state, payload) {
      state.currentRole = payload;
    },
    setCurrentPhoto(state, payload) {
      state.currentUserPhoto = payload;
    },
    setCurrentCountry(state, payload) {
      state.currentCountry = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
