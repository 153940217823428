import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '../views/dashboard.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/safari-packages.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/quotations',
        name: 'Quotations',
        component: () => import(/* webpackChunkName: "quotations" */ '../views/quotations.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/safari-packages',
        name: 'SafariPackages',
        component: () => import(/* webpackChunkName: "safari-packages" */ '../views/safari-packages.vue'),
        meta: { requiresLogin: true }
      },

      {
        path: '/blogs',
        name: 'Blogs',
        component: () => import(/* webpackChunkName: "safari-packages" */ '../views/blogs.vue'),
        meta: { requiresLogin: true }
      },

      {
        path: '/add-safari-blog',
        name: 'AddSafariBlog',
        component: () => import(/* webpackChunkName: "add-safari-package" */ '../views/add-safari-blog.vue'),
        meta: { requiresLogin: true }
      },

      {
        path: '/edit-safari-blog/:id',
        name: 'EditSafariBlog',
        component: () => import(/* webpackChunkName: "add-safari-package" */ '../views/edit-safari-blog.vue'),
        meta: { requiresLogin: true }
      },
{
      path: '/blogs/:blogId',
      name: 'BlogPackages',
      component: () => import(/* webpackChunkName: "experience-packages" */ '../views/blog-packages.vue'),
      meta: { requiresLogin: true }
    },
      {
        path: '/add-safari-package',
        name: 'AddSafariPackage',
        component: () => import(/* webpackChunkName: "add-safari-package" */ '../views/add-safari-package.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/edit-safari-package/:packageId',
        name: 'EditSafariPackage',
        component: () => import(/* webpackChunkName: "edit-safari-package" */ '../views/edit-safari-package.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:packageTitle/:packageId/days',
        name: 'Days',
        component: () => import(/* webpackChunkName: "days" */ '../views/days.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:packageTitle/:packageId/activities',
        name: 'Activities',
        component: () => import(/* webpackChunkName: "activities" */ '../views/activities.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/countries',
        name: 'Countries',
        component: () => import(/* webpackChunkName: "countries" */ '../views/countries.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/add-country',
        name: 'AddCountry',
        component: () => import(/* webpackChunkName: "add-country" */ '../views/add-country.vue'),
        meta: { requiresLogin: true }
      },

      {
        path: '/add-hotel',
        name: 'AddHotel',
        component: () => import(/* webpackChunkName: "add-country" */ '../views/add-hotel.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/edit-country/:countryId',
        name: 'EditCountry',
        component: () => import(/* webpackChunkName: "edit-country" */ '../views/edit-country.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:countryName/destinations/:countryId',
        name: 'Destinations',
        component: () => import(/* webpackChunkName: "destinations" */ '../views/destinations.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/:countryName/destination-packages/:destinationId/:destinationName',
        name: 'DestinationPackages',
        component: () => import(/* webpackChunkName: "destination-packages" */ '../views/destination-packages.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/experiences',
        name: 'Experiences',
        component: () => import(/* webpackChunkName: "experiences" */ '../views/experiences.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/experiences/:experienceId',
        name: 'ExperiencePackages',
        component: () => import(/* webpackChunkName: "experience-packages" */ '../views/experience-packages.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/safari-types',
        name: 'SafariTypes',
        component: () => import(/* webpackChunkName: "safari-types" */ '../views/safari-types.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/safari-types/:SafariTypeId',
        name: 'SafariTypesPackages',
        component: () => import(/* webpackChunkName: "safari-types-packages" */ '../views/safari-types-packages.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/news-letter-subscribers',
        name: 'NewsLetters',
        component: () => import(/* webpackChunkName: "users" */ '../views/news-letter-subscribers.vue'),
        meta: { requiresLogin: true }
      },

      {
        path: '/hotels',
        name: 'Hotels',
        component: () => import(/* webpackChunkName: "users" */ '../views/hotels.vue'),
        meta: { requiresLogin: true }
      },

      {
        path: '/receipts',
        name: 'Receipts',
        component: () => import(/* webpackChunkName: "users" */ '../views/receipts.vue'),
        meta: { requiresLogin: true }
      },

      {
        path: '/customer-reviews',

        name: 'Reviews',
        component: () => import(/* webpackChunkName: "reviews" */ '../views/Reviews.vue'),
        meta: { requiresLogin: true }
      },
      {
        path: '/payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: "payment" */ '../views/Payment.vue'),
      },

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },

  //In case the route is anything else
  {
    path: '*',
    redirect: '/dashboard/home'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  if (to.name === "Login" && localStorage.getItem('user')) {
    next({ path: "/" });
  }

  //Check if login is required for Routes with meta data loginRequired!
  if (to.matched.some(record => record.meta.requiresLogin)) {
    if (!localStorage.getItem('user')) {
      //Redirect to Login Page
      next({ path: "/login" });
    } else next();
  } else next();
});

export default router
